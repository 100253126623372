import { useState, useEffect } from "react";
import "../../../modules/fingerprint-steps/fingerprint-narrative/CraftNarrative.scss";
import {
  changeLicenseStatus,
  getNarrativeData,
  getUserProfile,
} from "../../../services/user.service";
import { useParams } from "react-router";
import { FingerPrint } from "../../../components/Fingerprint/FingerPrint";
import SessionService from "../../../services/session.service";
import { ISessionUserDto } from "../../../dtos/session-user.dto";
import { useValueOrder, useValuePhilosophy } from "../Survey/Survey.hooks";
import { useSaveFingerprintData } from "../FingerPrint.hooks";
import API from "../../../API";

export const FingerPrintResult = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [narrativeData, setNarrativeData] = useState<any>({});
  const [userData, setUserData] = useState<ISessionUserDto | null>(null);

  const params = useParams<{ licenseCode?: string }>();

  const { saveFingerprintData } = useSaveFingerprintData();

  const { user } = SessionService.getInstance();

  const { getValueOrder, isLoading: isLoadingValueOrder } = useValueOrder();

  const { getValuePhilosophy, isLoading: isLoadingValuePhilosophy } =
    useValuePhilosophy(user?.id || 0);

  const loadValues = async () => {
    try {
      const narrativeData = await getNarrativeData(params?.licenseCode || "");

      // UI
      setNarrativeData(narrativeData);

      if (narrativeData?.valuesPhilosophy) {
        setIsLoading(false);

        return;
      }

      // Fetch value order
      const { value_drivers, value_drivers_explanation } = await getValueOrder(
        user?.id || 0,
        narrativeData.rankedValues.split(","),
        narrativeData.surveyData,
        true
      );

      // Value order failed
      if (!value_drivers || !value_drivers_explanation) {
        setIsLoading(false);

        return;
      }

      // Fetch Value Philosophy
      const { values_philosophy: valuesPhilosophy } = await getValuePhilosophy(
        value_drivers,
        value_drivers_explanation
      );

      // Value Philosophy failed
      if (!valuesPhilosophy) {
        setIsLoading(false);

        return;
      }

      // UI
      setNarrativeData({ ...narrativeData, valuesPhilosophy });

      const {
        userId,
        licenseCode,
        rankedValues,
        valuesExplanation,
        archetype,
        archetypeExplanation,
        triggers,
        triggersExplanation,
      } = narrativeData;

      // DB
      await API.post("narrative/save-values", {
        userId,
        licenseCode,
        values: rankedValues,
        valuesExplanation,
        archetype,
        archetypeExplanation,
        triggers,
        triggersExplanation,
        valuesPhilosophy,
      });

      changeLicenseStatus(licenseCode, "completed");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadUserProfile = async () => {
    if (!narrativeData?.userId) {
      return;
    }

    try {
      const userData = await getUserProfile(parseInt(narrativeData?.userId));

      setUserData(userData);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    saveFingerprintData();

    loadValues();
  }, []);

  useEffect(() => {
    if (narrativeData?.userId) {
      loadUserProfile();
    }
  }, [narrativeData?.userId]);

  // const valueDrivers = getValueDrivers(surveyResultsFinal);

  return (
    <FingerPrint
      isLoading={isLoading || isLoadingValueOrder || isLoadingValuePhilosophy}
      userName={`${userData?.firstName || ""} ${userData?.lastName || ""}`}
      licenseCode={params?.licenseCode || ""}
      valueDrivers={narrativeData?.rankedValues?.split(",")}
      valuePhilosophy={narrativeData?.valuesPhilosophy}
      archetype={narrativeData?.archetype}
      archetypeExplanation={narrativeData?.archetypeExplanation}
      triggers={narrativeData?.triggers?.split(",")}
      triggersExplanation={narrativeData?.triggersExplanation
        ?.split(";;")
        ?.reduce((acc: any, curr: any) => {
          const explanation = curr?.split(":");

          return {
            ...acc,
            [explanation[0]]: explanation[1],
          };
        }, {})}
    />
  );
};
