import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import { TwoShapes } from "./features/shapes/Shapes";
import { RootState } from "./app/store";
import { Routes } from "./Routes";
import { statusToVariant } from "./services/user.service";
import { useValueData } from "./app/hooks";
import { MessageBox } from "./modules/helpers/message-box/message-box";

const App = () => {
  const selectorError = useSelector((state: RootState) => {
    return state.editHttpError;
  });

  const [currentRoute, setCurrentRoute] = useState(
    window.location.pathname.replace("/", "")
  );

  const { CalculateValueScores } = useValueData();

  const selector = useSelector((state: RootState) => {
    return state.editForm;
  });

  const sendRoutesToParent = () => {
    setCurrentRoute(window.location.pathname.replace("/", ""));
  };

  const findPotentialValues = (data: any): string => {
    let potentialValues: string = "";
    let addedValues: string = "";

    if (data["potential-values"]) {
      potentialValues = data["potential-values"]["potential-values"];
    }
    if (data["add-values"]) {
      addedValues = data["add-values"]["add-values"];
    }
    if (!data["add-values"]) {
      return potentialValues;
    }

    if (addedValues === "" && potentialValues === "") {
      return "";
    }

    const route = "mapping-question";

    let values: Array<string> = [];
    if (data[route] !== undefined) {
      (potentialValues + "," + addedValues).split(",").map((value, index) => {
        if (
          data[route][value] !==
            "On further reflection, I do not value this." &&
          value !== ""
        ) {
          values.push(value);
        }
      });
    }

    return values.toString();
  };

  useEffect(() => {
    if (selector !== null) {
      if (Object.keys(selector).length !== 0) {
        CalculateValueScores(findPotentialValues(selector));
      }
    }
  }, [selector]);

  return (
    <>
      <Router>
        <Route path="/">
          <Routes sendRoutesToParent={sendRoutesToParent} />
        </Route>
      </Router>

      {selectorError.statusCode > 399 && (
        <MessageBox
          messages={selectorError.messages}
          variant={statusToVariant(selectorError.statusCode)}
          class="absolute-message"
        />
      )}

      <TwoShapes currentPath={currentRoute} />
    </>
  );
};

export default App;
